
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import Callcenter from '@/components/Callcenter'
import Location from '@/components/Location'
import Logo from '@/components/Logo'

export default {
  components: {
    Callcenter,
    Location,
    Logo
  },

  mixins: [
    clickaway
  ],

  data: () => ({
    menu: [
      {
        id: 1,
        title: 'payment',
        slug: 'payment',
        children: []
      },
      {
        id: 2,
        title: 'delivery',
        slug: 'delivery',
        children: []
      },
      {
        id: 3,
        title: 'faq',
        slug: 'faq',
        children: []
      },
      {
        id: 4,
        title: 'contacts',
        slug: 'contacts',
        children: []
      }
    ]
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      location: 'location'
    })
  }
}
