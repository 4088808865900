
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  data () {
    return {
      isMounted: false,
      isActive: false,
      swiperOption: {
        // simulateTouch: false,
        lazy: true,
        loop: true,
        slidesPerView: 'auto',
        autoplay: {
          delay: 1000
        },
        allowTouchMove: false,
        on: {
          slideChange () {
            // console.log('onSlideChangeEnd', this)
          },
          tap () {
            // console.log('onTap', this.realIndex)
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      support: 'support/support',
      callback: 'modal/callback',
      email: 'modal/email',
      settings: 'settings'
    }),

    operating () {
      const schedule = this.support.schedule.days
      const timeFormat = 'hh:mm'
      let operating

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open)) {
          operating = {
            active: false,
            message: this.$t('support.today.notYet', {
              weekday: moment.format('dd').toUpperCase(),
              open: schedule[weekday - 1].open,
              close: schedule[weekday - 1].close
            })
          }
        } else if (moment.isBetween(open, close)) {
          operating = {
            active: true,
            message: this.$t('support.today.operating', {
              close: schedule[weekday - 1].close
            })
          }
        } else {
          operating = {
            active: false,
            message: this.$t('support.tomorrow', {
              open: schedule[weekday !== 7 ? weekday : 0].open
            })
          }
        }
      } else if (schedule === '') {
        operating = {
          active: true,
          message: this.$t('support.allDay')
        }
      }

      return operating
    }
  },

  watch: {
    $route (to, from) {
      this.pageHasFixedButton()
      this.$store.dispatch('support/setProductFixedPriceButton', false)
    }
  },

  async mounted () {
    await this.$nextTick(async () => {
      await this.pageHasFixedButton()
    })

    if (this.$refs.callback) {
      this.$refs.callback.addEventListener('click', (event) => {
        this.$store.dispatch('modal/setCallbackActive', true)
      }, { passive: true })
    }
  },

  methods: {
    toggleDropDown () {
      this.isActive = !this.isActive
    },

    hideSupportContactDropdown () {
      this.isActive = false
    },

    async pageHasFixedButton () {
      await this.$store.dispatch(
        'support/setProductFixedPriceButton',
        this.$route.name.startsWith('slug___') &&
          this.support.productFixedPriceButton &&
          this.$route.query.tab !== 'stores' // &&
          // ['mobileSmall', 'mobile'].includes(this.$mq)
      )
      this.isMounted = true
    },

    clickContact (contact) {
      if (contact === 'callback') {
        this.$store.dispatch('modal/setCallbackActive', true)
      } else if (contact === 'email' && this.email.tabs.length) {
        this.$store.dispatch('modal/setEmailTab', this.email.tabs[0])
        this.$store.dispatch('modal/setEmailActive', true)
      }
    }
  }
}
