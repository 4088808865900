
import { mapGetters } from 'vuex'

const __default__ = {
  computed: {
    ...mapGetters({
      currency: 'currency',
      notification: 'notification'
    }),

    link () {
      return {
        name: 'slug',
        params: {
          slug: this.notification.bannerable.page.slug
        }
      }
    }
  },

  methods: {
    close (e) {
      e.preventDefault()

      this.$store.dispatch('setNotificationActivity', false)
    },

    background (notification) {
      if (this.$mq === 'fullhd' && this.$mq in notification.backgrounds) {
        return notification.backgrounds.fullhd
      } else if (this.$mq === 'widescreen' && this.$mq in notification.backgrounds) {
        return notification.backgrounds.widescreen
      } else if (this.$mq === 'desktop' && this.$mq in notification.backgrounds) {
        return notification.backgrounds.desktop
      } else if (this.$mq === 'tablet' && this.$mq in notification.backgrounds) {
        return notification.backgrounds.tablet
      } else if (this.$mq === 'mobile' && this.$mq in notification.backgrounds) {
        return notification.backgrounds.mobile
      } else {
        return notification.backgrounds.mobileSmall
      }
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "0649aa8b": (_vm.notification.color.value)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__